/*
 * Default layout
 */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #f4fbff;
  -webkit-overflow-scrolling: touch;
}

.page {
  overflow-x: hidden;
}

a {
  text-decoration: none;

  &.btn {
    cursor: pointer;
    display: block;
    max-width: 290px;
    margin: 0 auto;
    font-family: 'ProximaNova-Regular', sans-serif;
    color: #333;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    border-radius: 0.275rem;

    &-gold {
      background-color: #F0C734;
      transition: 0.3s all;
      font-size: 1.125rem;

      &:hover {
        background-color: #f5b120;
        color: #000;
      }
    }

    &-join {
      font-size: 1.125rem;
      padding: 1.2rem 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    display: flex;
  }
}

input {
  background: none;
  border: none;
  cursor: pointer;

  &:active {
    outline: none;
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
  }
}

button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.menu a, .mobile-menu a {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    height: 1px;
    background: #fff;
    right: 0;
    width: 0;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover:after,
  &:focus:after,
  &:active:after {
    left: 0;
    right: auto;
    width: 100%;
  }
}

/*
 * Scss imports
 */

@import 'normalize.scss';
@import 'fonts.scss';
@import 'base.scss';
@import 'media.scss';
