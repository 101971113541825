html {

}

section {
  padding: 2.1875rem 0;
}

svg {
  cursor: default;

  text {
    cursor: default;
  }
}

.container {
  padding: 0 2.625rem;
}

p {
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 1.25rem;
  line-height: 1.6;
}

h2, h3 {
  font-family: 'ProximaNova-Bold', sans-serif;
  font-weight: 600;
}

.bg-white {
  background-color: #FFFFFF;
}

.btn {
  font-family: 'ProximaNova-Regular', sans-serif;
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  border-radius: 0.275rem;

  &-gold {
    background-color: #F0C734;
    transition: 0.3s all;
    font-size: 1.125rem;

    &:hover {
      background-color: #f5b120;
      color: #000;
    }
  }

  &-join {
    font-size: 1.125rem;
    padding: 1.2rem 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.header {
  padding: 1.5rem 0;
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      margin-right: 15px;

      h2 {
        margin: 0;

        a {
          color: #000;
        }
      }
    }

    .authentication {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 15px;

      .login {
        margin-right: 1.125rem;
        transition: 0.3s all;

        &:hover {
          color: #019bd3;
        }
      }

      .sign-up {
        padding: 0.5rem 1.25rem;
      }
    }
  }
}

.join-us {
  padding-top: 7.9rem;
  text-align: center;
  padding-bottom: 0.2rem;

  h1 {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-size: 2.625rem;
    line-height: 1.21429;
    display: block;
    margin: 0 auto 4.375rem;

    span {
      color: #019bd3;

      &.pro-label {
        position: relative;

        svg {
          max-width: 5rem;
          height: 4.6875rem;
          top: 90%;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  p {
    margin-bottom: 1.8rem;
  }

  &_img {
    margin-bottom: 2.6rem;

    img {
      @media (min-width: 993px) {
        max-width: 50%;
      }
    }
  }
}

.features {

  .features-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;

    .features-card {
      height: 100%;
      display: flex;
      justify-content: space-between;
      @media (min-width: 768px) {
        flex-direction: column;
      }

      &_img {
        max-width: 5.375rem;
        width: 100%;
        margin: 0 auto 1.25rem;

        svg {
          width: 100%;
          height: 5.375rem;
        }
      }

      &_body {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      h3 {
        font-size: 1.375rem;
        line-height: 1.75rem;
        color: #019bd3;
        height: 3.5rem;
        overflow: hidden;

        @media (min-width: 768px) {
          text-align: center;
        }
      }

      p {
        font-family: 'ProximaNovaA-Thin', sans-serif;
        font-weight: 300;
        color: #171717;
        font-size: 1.125rem;
        line-height: 1.55556;
        max-height: 17.5rem;
        overflow: hidden;
      }
    }
  }
}

.join-us_main {

  h2 {
    max-width: 56rem;
    margin: 0 auto 1.5rem;
  }

  .join-us_main-wrap {
    text-align: center;
  }
}

.marketplaces {
  text-align: center;

  h2 {
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 2rem;
  }

  .marketplaces-flags {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &_block {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.5rem;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .flag {
      max-width: 4rem;
      margin-right: 1rem;

      svg {
        width: 100%;
        height: 2.9375rem;
      }

      p {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.875rem;
        font-family: 'ProximaNova-Bold', sans-serif;
        font-weight: 600;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.footer {
  background-color: #333945;
  padding: 3rem 0 1rem;
  font-size: 1.125rem;
  color: white;

  &-grid {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5rem;

    &_column {

      &-description {
        max-height: 9.375rem;
        overflow: hidden;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.5625rem;
        margin-bottom: 1rem;
      }

      .footer-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {

          &:first-child {
            &:before {
              content: none;
            }

            a {
              padding-left: 0;
            }
          }

          &:before {
            content: '|';
            font-size: 0.875rem;
            line-height: 1.5625rem;
            color: #ABE7F4;
          }

          a {
            font-size: 0.875rem;
            line-height: 1.5625rem;
            color: #ABE7F4;
            padding: 0 0.2rem;
            transition: 0.3s all;
            white-space: nowrap;

            @media (max-width: 375px) {
              font-size: 0.77rem;
            }

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }

      h2 {
        a {
          color: #fff;
        }

        @media (max-width: 991px) {
          text-align: right;
        }

        @media (max-width: 767px) {
          text-align: center;
        }
      }

      .footer-list_contacts {

        &-img {
          min-width: 2.25rem;
          max-width: 2.25rem;
          height: 1.6875rem;
          margin-right: 1.125rem;

          svg {
            width: 100%;
            height: 1.6875rem;
          }
        }

        li {
          margin-bottom: 2rem;
          display: flex;

          a {
            font-family: 'ProximaNova-Regular', sans-serif;
            color: #fff;
            font-size: 1rem;
            line-height: normal;
            transition: 0.3s all;

            &:hover {
              opacity: 0.5;
            }
          }

          p {
            font-size: 1rem;
          }
        }
      }
    }
  }

  &-bottom {
    border-top: 1px solid #ABE7F4;
    padding: 2rem 0 1rem;
    text-align: center;
  }
}
