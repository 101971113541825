@media (min-width: 1482px) {
  html {
    font-size: 20px;
  }

  .container {
    padding: 0;
    margin: 0 auto;
    max-width: 1400px;
  }
}

@media (max-width: 1024px) {
  .marketplaces {

    .marketplaces-flags {
      flex-direction: column;

      &_block {
        margin-right: 0;
        margin-bottom: 1.25rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .features .features-grid {
    grid-template-columns: 1fr 1fr;
  }

  .footer {

    &-grid {
      grid-template-columns: 1fr;

      &_column {
        margin-bottom: 2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .header-wrap {
    .logo {
      margin-right: 0;
    }
  }

  .container {
    padding: 0 1.5rem;
  }

  .join-us h1 img {
    right: 0;
  }


  .features .features-grid {
    grid-template-columns: 1fr;

    .features-card {

      &_img {
        margin: 0 1rem 0 0;
      }
    }
  }
}

@media (max-width: 500px) {
  .features .features-grid {

    .features-card {

      &_img {
        max-width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
      }
    }
  }

  .marketplaces .marketplaces-flags .flag svg {
    width: 2.5rem;
    height: 2.375rem;
  }
}

@media (max-width: 375px) {
  .header .header-wrap {
    flex-direction: column;

    .logo {
      margin-bottom: 1rem;
    }
  }

  .join-us {
    padding-top: 10.5875rem;
  }
}
