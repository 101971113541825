@font-face {
  font-family: 'ProximaNovaA-Thin';
  src: url('../fonts/ProximaNovaA-Thin.woff2') format('woff2'),
  url('../fonts/ProximaNovaA-Thin.woff') format('woff'),
  url('../fonts/ProximaNovaA-Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('../fonts/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
  url('../fonts/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
